import "./Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import pdfIcon from "./papers/pdf.svg";

export const papersData = [
  {
    id: 136,
    name: "Assessing the Suitability of Ensemble Learning for Consumer Behavior Prediction in Neuromarketing",
    pageNumber: "1-6",
    authorData: [
      {
        name: "A. Rama,",
        designation: "Head of the Department",
        department: "Department of Research & Innovation",
        university:
          "Saveetha School of Engineering, SIMATS Deemed University, Chennai, Tamil Nadu, India.",
      },
      {
        name: "S. Anusuya",
        department: "Department of Information Technology",
        university:
          "Saveetha School of Engineering, Chennai, Tamil Nadu, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V3.01.01",
    keywords:
      "Ensemble Learning, Neuromarketing, EEG Signals, Consumer Behavior, K-Nearest Neighbors, Decision Tree, Naive Bayes.",
    abstract:
      "In the dynamic field of neuromarketing, accurately predicting consumer behaviour is critical for designing effective and targeted marketing strategies. This study assesses the suitability of an Ensemble learning approach, integrating K-Nearest Neighbors (KNN), Decision Tree, and Naive Bayes algorithms, to enhance predictive performance in consumer behavior analysis. Using a synthetic dataset with normally distributed attributes, the models were evaluated individually and as part of the Ensemble framework based on precision, recall, and accuracy metrics. Results indicate that the Ensemble model outperformed its base models, achieving the highest recall (91.67%) and overall accuracy (58.33%), demonstrating its ability to capture a broad range of consumer behaviors. While KNN performed competitively in recall, and Decision Tree showed moderate capabilities, both fell short in accuracy and robustness. Naive Bayes, constrained by its assumptions of feature independence, exhibited the lowest overall performance. This research highlights the potential of Ensemble learning to address the complexities of real-world consumer datasets and emphasizes its suitability for neuromarketing applications requiring high recall and adaptability. The findings underline the importance of leveraging Ensemble methods to optimize predictive capabilities in forecasting consumer behavior.",
    published: "2025-01-20",
    source: "Vol 03. Issue. 01 Jan (2025)",
    cite: "A. Rama, S. Anusuya, “Assessing the Suitability of Ensemble Learning for Consumer Behavior Prediction in Neuromarketing”, Knowledge Transactions on Applied Machine Learning, Vol. 03, Issue. 01, pp. 1–6, Jan. 2025. DOI: https://doi.org/10.59567/ktAML.V3.01.01",
  },
  {
    id: 137,
    name: "Enhancing ABG Data Prediction: A Comprehensive Comparative Study of Neural Network Architectures",
    pageNumber: "07-11",
    authorData: [
      {
        name: "S. Ramadoss,",
        designation: "Assistant Professor,",
        department: "School of Computing,",
        university:
          "Bharath Institute of Higher Education and Research, India, Chennai.",
      },
      {
        name: "K. Prabhu",
        designation: "Professor,",
        department: "Department of Anatomy,",
        university:
          "Sree Balaji Medical College and Hospital, Tamil Nadu, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V3.01.02",
    keywords:
      "ABG Analysis, Neural Networks, Machine Learning, Respiratory Imbalance, Dropout Regularization, Medical Diagnostics.",
    abstract:
      "Arterial Blood Gas (ABG) analysis plays a pivotal role in diagnosing respiratory and metabolic imbalances in critically ill patients. Traditional manual interpretation methods are inherently time- consuming and susceptible to human error, thereby motivating the development of automated computational approaches to streamline diagnostics. The advent of machine learning, particularly neural networks, offers promising solutions for enhancing diagnostic accuracy and reliability. This study investigates the comparative efficacy of two neural network architectures: a Simple Neural Network (Simple NN) with homogeneous activation functions and an Augmented Neural Network (Augmented NN) that integrates heterogeneous activation functions and dropout regularization to mitigate overfitting. The framework leverages ABG datasets comprising key physiological parameters such as pH, PaCO₂, and HCO₃ were collected in real-time at Balaji Hospital. Training protocols utilized categorical cross-entropy loss with ReLU activation for the Simple NN, while the Augmented NN incorporated a combination of ReLU, tanh, sigmoid, and softmax activations across its layers. Dropout layers were strategically integrated into the Augmented NN to improve generalization. Results demonstrate that the Augmented NN achieves superior performance, with a validation accuracy of 94%, underscoring its robustness and predictive reliability. This study contributes a critical evaluation of neural network configurations for medical diagnostics, advancing the application of machine learning in healthcare and paving the way for future research in automated diagnostic tools.",
    published: "2025-01-20",
    source: "Vol 03. Issue. 01 Jan (2025)",
    cite: "S. Ramadoss, K. Prabhu, “Enhancing ABG Data Prediction: A Comprehensive Comparative Study of Neural Network Architectures”, Knowledge Transactions on Applied Machine Learning, Vol. 03, Issue. 01, pp. 07–11, Jan. 2025. DOI: https://doi.org/10.59567/ktAML.V3.01.02",
  },
  {
    id: 138,
    name: "Leveraging Usage-Based SaaS Models: Optimizing Revenue and User Experience",
    pageNumber: "12-17",
    authorData: [
      {
        name: "G. Sai Chaitanya Kumar,",
        designation: "Professor,",
        department: "Department of CSE,",
        university:
          "DVR & Dr HS MIC College of Technology, Kanchikcherla, NTR Dt - 5211802.",
      },
      {
        name: "B. Dhanalaxmi",
        designation: "Professor,",
        department: "Department of CSE,",
        university:
          "Malla Reddy Institute of Engineering and Technology, Hyderabad.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V3.01.03",
    keywords:
      "SaaS, Usage-Based Pricing, Customer Retention, Revenue Optimization, Billing Framework, Machine Learning, Data Analytics, Risk Sharing, Outcome-Based Models.",
    abstract:
      "Software as a Service (SaaS) has transformed how businesses and consumers access and pay for software, with usage-based SaaS models emerging as a compelling alternative to traditional subscription models. This study explores the evolution of usage-based SaaS pricing, focusing on its ability to align revenue generation with customer value realization. Motivated by the demand for cost flexibility and scalable solutions, the aim is to identify strategies that optimize financial outcomes for providers while enhancing user satisfaction. By analyzing current models, proposing a data-driven framework for usage tracking, and investigating customer-centric billing approaches, this research offers actionable insights into balancing profitability with customer loyalty. Preliminary findings suggest that a tailored usage-based approach promotes sustainable growth and customer retention.",
    published: "2025-01-20",
    source: "Vol 03. Issue. 01 Jan (2025)",
    cite: "G. Sai Chaitanya Kumar , B. Dhanalaxmi “Leveraging Usage-Based SaaS Models: Optimizing Revenue and User Experience”, Knowledge Transactions on Applied Machine Learning, Vol. 03, Issue. 01, pp. 12-17, Jan. 2025. DOI: https://doi.org/10.59567/ktAML.V3.01.03",
  },
];

function Papers() {
  const basePath = window.location.origin;
  return (
    <Container className='pt-3 pb-5'>
      <Row>
        <Col>
          <h2 className='sub-heading m-0'>Papers</h2>
          <hr className='heading-line' />
        </Col>
      </Row>
      {papersData.map(function (paper, i) {
        return (
          <Row style={{ paddingBottom: "2.25rem" }}>
            <Col lg={11}>
              <Link
                key={paper.id}
                to={`/paper/${paper.id}`}
                target={"_blank"}
                className='flex-style paper-info'
                rel='noopener noreferrer'
                style={{ justifyContent: "start", textDecoration: "none" }}
              >
                {paper.name}
              </Link>
              {paper.authorData.map((data) => {
                return (
                  <span className='paper-author' style={{ lineHeight: "36px" }}>
                    {data.name}&nbsp;&nbsp;
                  </span>
                );
              })}

              <Link
                to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                target={"_blank"}
                className='flex-style py-2'
                rel='noopener noreferrer'
                style={{ justifyContent: "start", textDecoration: "none" }}
              >
                <span className='download-button'>
                  <Button
                    variant='secondary'
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      padding: "3px 6px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pdfIcon}
                      alt=''
                      style={{ width: "25px", margin: "0 5px" }}
                    />{" "}
                    Download
                  </Button>{" "}
                </span>
              </Link>
            </Col>
            <Col lg={1} className='flex-style' style={{ alignItems: "start" }}>
              <p key={i} className='page-number'>
                pp. {paper.pageNumber}
              </p>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}

export default Papers;
