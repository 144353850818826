import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./PageComponents/HomePage/Home";
import AuthorsPage from "./PageComponents/AuthorsPage/Authors";
import ScopePage from "./PageComponents/ScopePage/Scope";
import ContactPage from "./PageComponents/ContactPage/Contact";
import CurrentIssues from "./PageComponents/IssuesPage/CurrentIssues";
import ArchivesPage from "./PageComponents/ArchivesPage/Archives";
import PrivacyPage from "./PageComponents/PrivacyPage/Privacy";
import SubmissionPage from "./PageComponents/SubmissionPage/Submission";
import GuidelinesForAuthors from "./PageComponents/GuidelinesPage/Guidelines";
import AbstractingandIndexing from "./PageComponents/AbstractingPage/Abstracting";
import JournalLandingPage from "./PageComponents/JournalLandingPages/JournalLandingPage";
import ArchivedIssues1 from "./PageComponents/ArchivedIssues/Issue1";
import ArchivedIssues2 from "./PageComponents/ArchivedIssues/Issue2";
import ArchivedIssues3 from "./PageComponents/ArchivedIssues/Issue3";
import ArchivedIssues4 from "./PageComponents/ArchivedIssues/Issue4";
import ArchivedIssues5 from "./PageComponents/ArchivedIssues/Issue5";
import ArchivedIssues6 from "./PageComponents/ArchivedIssues/Issue6";
import ArchivedIssues7 from "./PageComponents/ArchivedIssues/Issue7";
import ArchivedIssues8 from "./PageComponents/ArchivedIssues/Issue8";
import ArchivedIssues9 from "./PageComponents/ArchivedIssues/Issue9";
import ArchivedIssues10 from "./PageComponents/ArchivedIssues/Issue10";
import ArchivedIssues11 from "./PageComponents/ArchivedIssues/Issue11";
import ArchivedIssues12 from "./PageComponents/ArchivedIssues/Issue12";

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route exact path='/paper/:id' element={<JournalLandingPage />} />
        <Route path='/authors' element={<AuthorsPage />} />
        <Route path='/scope' element={<ScopePage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/current-issues' element={<CurrentIssues />} />
        <Route path='/archives' element={<ArchivesPage />} />
        <Route path='/privacy' element={<PrivacyPage />} />
        <Route path='/submission' element={<SubmissionPage />} />
        <Route
          path='/guidelines-for-authors'
          element={<GuidelinesForAuthors />}
        />
        <Route
          path='abstracting-and-indexing'
          element={<AbstractingandIndexing />}
        />
        <Route
          exact
          path='/archived-issues/101'
          element={<ArchivedIssues1 />}
        />
        <Route
          exact
          path='/archived-issues/102'
          element={<ArchivedIssues2 />}
        />
        <Route
          exact
          path='/archived-issues/103'
          element={<ArchivedIssues3 />}
        />
        <Route
          exact
          path='/archived-issues/104'
          element={<ArchivedIssues4 />}
        />
        <Route
          exact
          path='/archived-issues/105'
          element={<ArchivedIssues5 />}
        />
        <Route
          exact
          path='/archived-issues/106'
          element={<ArchivedIssues6 />}
        />
        <Route
          exact
          path='/archived-issues/107'
          element={<ArchivedIssues7 />}
        />
        <Route
          exact
          path='/archived-issues/108'
          element={<ArchivedIssues8 />}
        />
        <Route
          exact
          path='/archived-issues/109'
          element={<ArchivedIssues9 />}
        />
        <Route
          exact
          path='/archived-issues/110'
          element={<ArchivedIssues10 />}
        />
        <Route
          exact
          path='/archived-issues/111'
          element={<ArchivedIssues11 />}
        />
        <Route
          exact
          path='/archived-issues/112'
          element={<ArchivedIssues12 />}
        />
      </Routes>
    </div>
  );
}

export default App;
